<template>
    <AuthState>
        <div :class="showMenu ? 'flowHidden' : ''">
            <Header @menu-toggle="showMenu = !showMenu" />
            <Menu :show="showMenu" @menu-toggle="showMenu = !showMenu">
                <slot />
            </Menu>
        </div>
    </AuthState>
</template>

<script setup lang="ts">

import {EventTypes} from '~/constants';

const {logEvent} = useEventLog();
const showMenu = ref(false)
const app = useEventBus<string>('app')
const unsubscribe = app.on((event) => {
    switch (event) {
        case 'menu-toggle':
            showMenu.value = !showMenu.value
            break
    }
})

onMounted(()=> {
    logEvent(EventTypes.START_SESSION, {})

    window.addEventListener('beforeunload', function (e) {
        logEvent(EventTypes.CLOSE_SESSION, {})
    });
})

onUnmounted(() => {
    unsubscribe()
})

</script>
<style lang="scss">
.flowHidden {
    max-height: 100vh;
    overflow: hidden;
}
</style>
