<template>
    <div class="LanguageSelector">
        <select class="" v-model="currentLanguage">
            <option :value="language" v-for="language in languages">
                {{ language.toUpperCase() }}
            </option>
        </select>
    </div>
</template>

<script setup lang="ts">
const { currentLanguage, languages } = useLanguages();

</script>
<style lang="scss">
.LanguageSelector {}
</style>
