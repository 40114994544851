<template>
    <div class="Menu">
        <slot />
        <RenderCacheable>
            <div :class="{ overlay: props.show }" @click="app.emit('menu-toggle')" />

            <div :class="{ menu: true, show: props.show }">
                <p class="text-end m-0">
                    <a href="#" class="icon" @click="app.emit('menu-toggle')">
                        <Icon name="mdi:close" size="30" />
                    </a>
                </p>
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                    <ul class="m-0">
                        <li class="mb-2 text-uppercase">
                            <NuxtLink href="/" @click="app.emit('menu-toggle')">
                                {{ $t('menu.product_list') }}
                            </NuxtLink>
                        </li>
                        <li class="mb-2 text-uppercase">
                            <NuxtLink href="/dashboards" @click="app.emit('menu-toggle')">
                                {{ $t('menu.dashboard') }}
                            </NuxtLink>
                        </li>
                        <li class="text-uppercase">
                            <NuxtLink href="/products/visited" @click="app.emit('menu-toggle')">
                                {{ $t('menu.last_viewed') }}
                            </NuxtLink>
                        </li>
                    </ul>
                    <div class="line ms-auto me-auto">
                        <div class="border-bottom"></div>
                    </div>
                    <ul class="small m-0">
                        <li>
                            <NuxtLink href="/requests" @click="app.emit('menu-toggle')">
                                {{ $t('menu.requests') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink href="/comunicazioni">
                                {{ $t('menu.communication_archive') }}
                            </NuxtLink>
                        </li>
                    </ul>
                    <div class="line ms-auto me-auto">
                        <div class="border-bottom"></div>
                    </div>
                    <ul class="small flex-column d-flex justify-content-end m-0">
                        <li>
                            <NuxtLink href="/avvertenze">
                                {{ $t('menu.warnings') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink href="/contact_us">
                                {{ $t('menu.contact_us') }}
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </RenderCacheable>
    </div>
</template>
<script setup lang="ts">
const app = useEventBus<string>('app')
const props = defineProps({
    show: Boolean
})


</script>
<style lang="scss">
@import "~/styles/variables";
$transition-duration: .5s;

.Menu {
    position: relative;

    .icon {
        padding: 3px;
        display: inline-block;
        opacity: .8;

        &:hover {
            opacity: 1;
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    .menu {
        position: fixed;
        width: $side-column-width;
        height: 100vh;
        background-color: white;
        top: 0;
        left: -$side-column-width;
        transition: left $transition-duration;
        padding: 0 10px 50px 10px;
        flex-direction: column !important;
        display: flex;
        z-index: 1021;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, .3);

        .line {
            width: 80%;
        }

        &.show {
            left: 0;
        }

        .icon {
            padding: 20px;
        }

        a {

            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            list-style: none;

            &.small {
                >li {
                    a {
                        font-size: 1.5rem;
                        line-height: 2.0rem;
                    }

                    margin-bottom: 1.5rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            >li {
                a {
                    font-size: 2.0rem;
                    line-height: 3.75rem;
                }

                ul {
                    >li {
                        a {
                            font-size: 1rem;
                            padding-left: 0;
                            display: block;
                            transition: padding-left .5s ease-in-out;

                            &:hover {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vw;
        background-color: rgba($color: #fff, $alpha: 0.3);
        transition: background-color $transition-duration;
        z-index: 1021;
    }
}
</style>
